<template>
  <div class="book-view">
    <div class="status-bar">
      <div class="status-bar__back-button" v-if="result.id">
        <vs-button
          icon="arrow_back"
          class
          type="flat"
          color="dark"
          :to="{ name: 'Student', params: { student_id: result.student.id } }"
          title="back to student"
        ></vs-button>
      </div>
      <h2 class="status-bar__title" v-if="result.test">
        {{ result.test.name }}
      </h2>
      <div class="status-bar__manage-buttons">
        <vs-icon icon="arrow_right"></vs-icon>
      </div>
      <h3 v-if="result.student">
        <vs-icon icon="person"></vs-icon>
        {{ result.student.name }}
      </h3>
      <div class="status-bar__manage-buttons">
        <edit-result-button :result="result" />
        <delete-result-button :result="result" />
      </div>
    </div>
    <div class="view-content">
      <div class="result-data" v-if="result.id">
        <div class="student">
          <span class="result-data__label">Student</span>
          <span class="result-data__data">
            <vs-icon icon="person"></vs-icon>
            {{ result.student.name }}
          </span>
        </div>
        <div class="student">
          <span class="result-data__label">Test time</span>
          <span class="result-data__data">
            <vs-icon icon="query_builder"></vs-icon>
            {{ $moment(result.test_time * 1000).format("DD.MM.YYYY") }}
          </span>
        </div>
        <div class="result-data__result">
          <span class="result-data__label">Result</span>
          <span class="result-data__data"
            >{{ result.value }}/{{ result.test.max_value }}</span
          >
        </div>
        <div class="result-data__result">
          <span class="result-data__label">Percent</span>
          <span class="result-data__data">{{ result.persent }}%</span>
        </div>
        <div>
          <span class="result-data__label">Book</span>
          <span class="result-data__data">
            <router-link
              :to="{ name: 'Book', params: { book_id: result.book.id } }"
              v-if="result.book"
            >
              <vs-icon icon="import_contacts"></vs-icon>
              {{ result.book.name }}
            </router-link>
          </span>
        </div>
        <div v-if="result.lesson">
          <span class="result-data__label">Lesson</span>
          <span class="result-data__data">
            <router-link
              :to="{ name: 'Lessons', params: { lesson_id: result.lesson.id } }"
              v-if="result.lesson"
            >
              {{ result.lesson.name }}
            </router-link>
          </span>
        </div>
        <div>
          <span class="result-data__label">Teacher</span>
          <span class="result-data__data">
            <vs-avatar
              v-if="result.owner"
              :src="
                result.owner.options && result.owner.options.avatar
                  ? `${proxy}/static/files/${result.owner.options.avatar}`
                  : undefined
              "
            ></vs-avatar
            >{{ result.owner.nickname }}
          </span>
        </div>
        <div v-if="result.group">
          <span class="result-data__label">Group</span>
          <span class="result-data__data">
            <router-link
              :to="{ name: 'Group', params: { group_id: result.group.id } }"
            >
              <vs-avatar icon="people" :color="result.group.color"></vs-avatar>
              <span
                >Group: {{ result.group.name }} ({{ result.group.schoolyear }})
              </span>
            </router-link>
          </span>
        </div>
      </div>
      <div class="result-comments">
        <Comments :itemId="$route.params.result_id" itemType="TestsResults" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Comments from "@/components/comments/comments.vue";
import DeleteResultButton from "../components/results/deleteResultButton.vue";
import EditResultButton from "../components/results/editResultButton.vue";

export default {
  title: "SE-TestResults",
  name: "ResultView",
  data() {
    return {
      popupActive: false,
      id: this.$route.params.result_id,
      today: new Date(),
      proxy: process.env.VUE_APP_PROXY,
    };
  },
  computed: {
    ...mapState({
      result: (state) => state.results.singleResult,
      student: (state) => state.students.singleStudent,
    }),
  },
  components: {
    Comments,
    DeleteResultButton,
    EditResultButton,
  },
  created() {
    this.$store
      .dispatch("results/getSingleResultAction", {
        id: this.$route.params.result_id,
      })
      .then(() => {
        // if (!this.student || data.student.id != this.student.id) {
        //   this.$store.dispatch("students/getSingleStudentAction", {
        //     id: data.student.id,
        //   });
        // }
      });
  },
};
</script>

<style>
</style>