<template>
  <div class="book-view">
    <div class="status-bar">
      <div class="status-bar__back-button" v-if="result.id">
        <vs-button
          icon="arrow_back"
          class
          type="flat"
          color="dark"
          :to="{ name: 'Student', params: { student_id: result.student.id } }"
          title="back to student"
        ></vs-button>
      </div>
      <h2 class="status-bar__title" v-if="result.test">Entry test</h2>
      <div class="status-bar__manage-buttons">
        <vs-icon icon="arrow_right"></vs-icon>
      </div>
      <h3 v-if="result.student">
        <vs-icon icon="person"></vs-icon>
        {{ result.student.name }}
      </h3>
      <div class="status-bar__manage-buttons">
        <edit-result-button :result="result" />
        <delete-result-button :result="result" />
      </div>
    </div>
    <div class="view-content">
      <div class="result-data" v-if="result.id">
        <div class="student">
          <span class="result-data__label">Student</span>
          <span class="result-data__data">
            <vs-icon icon="person"></vs-icon>
            {{ result.student.name }}
          </span>
        </div>
        <div class="student">
          <span class="result-data__label">Test time</span>
          <span class="result-data__data">
            <vs-icon icon="query_builder"></vs-icon>
            {{ $moment(result.test_time * 1000).format('DD.MM.YYYY') }}
          </span>
        </div>
        <div class="" v-if="result.type == 'TestsIncomeResult'">
          <span class="result-data__label">Test name</span>
          <span class="result-data__data">
            <vs-icon icon="import_contacts"></vs-icon>
            {{ result.test.name || '-' }}
          </span>
        </div>
        <div class="result-data__result">
          <span class="result-data__label">Oral</span>
          <span class="result-data__data">{{ result.oral_value }}</span>
        </div>
        <div class="result-data__result">
          <span class="result-data__label">Written</span>
          <span class="result-data__data">{{ result.written_value }}</span>
        </div>
        <div v-if="result.lesson">
          <span class="result-data__label">Lesson</span>
          <span class="result-data__data">
            <router-link
              :to="{ name: 'Lessons', params: { lesson_id: result.lesson.id } }"
              v-if="result.lesson"
            >
              {{ result.lesson.name }}
            </router-link>
          </span>
        </div>
        <div v-if="result.user">
          <span class="result-data__label">Teacher</span>
          <span class="result-data__data">
            <vs-avatar
              v-if="result.owner"
              :src="
                result.user.options && result.user.options.avatar
                  ? `${proxy}/static/files/${result.user.options.avatar}`
                  : undefined
              "
              size="small"
            ></vs-avatar
            >{{ result.user.nickname }}
          </span>
        </div>
        <div v-if="result.user">
          <span class="result-data__label">Comment</span>
          <span class="result-data__data" v-html="result.comment"> </span>
        </div>
        <div v-if="$can('income_result_send')">
          <div class="result-data__label">Sended</div>
          <div class="result sended" v-if="result.sended">
            <div>
              <vs-icon icon="done" color="success"></vs-icon>
              <span>{{
                $moment(result.sended * 1000).format('YYYY-MM-DD HH:mm')
              }}</span>
            </div>
            <vs-button
              icon="restore"
              color="primary"
              type="flat"
              size="small"
              @click="cancelSendResultHandler(result.id)"
              >Cancel</vs-button
            >
          </div>
          <div class="result" v-else>
            <vs-button
              icon="send"
              icon-after
              color="primary"
              type="flat"
              size="small"
              @click="sendResultHandler(result.id)"
              >Send</vs-button
            >
          </div>
        </div>
        <div v-if="result.group">
          <span class="result-data__label">Group</span>
          <span class="result-data__data">
            <router-link
              :to="{ name: 'Group', params: { group_id: result.group.id } }"
            >
              <vs-avatar icon="people" :color="result.group.color"></vs-avatar>
              <span
                >Group: {{ result.group.name }} ({{ result.group.schoolyear }})
              </span>
            </router-link>
          </span>
        </div>
      </div>
      <div class="result-comments">
        <Comments
          :itemId="$route.params.result_id"
          itemType="TestsIncomeResults"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Comments from '@/components/comments/comments.vue'
import DeleteResultButton from '../components/results/deleteResultButton.vue'
import EditResultButton from '../components/results/editResultButton.vue'

export default {
  title: 'SE-TestResults',
  name: 'ResultView',
  data() {
    return {
      popupActive: false,
      id: this.$route.params.result_id,
      today: new Date(),
      proxy: process.env.VUE_APP_PROXY,
    }
  },
  computed: {
    ...mapState({
      result: state => state.results.singleIncomeResult,
    }),
  },
  components: {
    Comments,
    DeleteResultButton,
    EditResultButton,
  },
  created() {
    this.getSingleResult()
  },
  methods: {
    getSingleResult: async function() {
      await this.$store.dispatch('results/getSingleIncomeResultAction', {
        id: this.$route.params.result_id,
      })
    },
    sendResultHandler: function(result_id) {
      const sended = new Date().getTime() / 1000
      this.incomeResultSendHandler(result_id, sended)
    },
    cancelSendResultHandler: function(result_id) {
      const sended = null
      this.incomeResultSendHandler(result_id, sended)
    },
    incomeResultSendHandler: async function(result_id, sended) {
      console.log(result_id, sended)
      await this.$store
        .dispatch('results/sendIncomeResultAction', { result_id, sended })
        .then(() => {
          this.$vs.notify({
            title: 'Success',
            text: `Tests saved`,
            color: 'success',
            position: 'top-right',
          })
        })
      this.getSingleResult()
    },
  },
}
</script>

<style lang="scss">
.result-data {
  .result {
    &.sended {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      & > div {
        margin-right: 0.5rem;
        span {
          vertical-align: bottom;
        }
      }
    }
  }
}
.result-comments {
  max-width: 700px;
}
</style>
